<template>
    <div class="flex justify-center form-box row-reverse col-12 mx-auto">
        <div class="col-12 lg-col-7 lg-pl3 pl2 lg-pr5 lg-pt1 mx-auto border-box" v-if="anfrageRunning == true && dataNotFound == false">
          Bitte haben Sie einen Moment Geduld, Ihre Anfrage wird jetzt verarbeitet....
          <div style="text-align: center; margin-top: 2rem">
              <img src="/assets/ajax-loader.gif">
            </div>
        </div>

        <div class="col-12 lg-col-7 lg-pl3 pl2 lg-pr5 lg-pt1 mx-auto border-box" v-if="dataNotFound == true">
          <h2 class="h2 mb2">Ihr Testdatum konnte nicht gefunden werden.</h2>
          <p class="p mb4">Haben Sie auf das richtige Datumsformat (tt.mm.jjjj) geachtet? Haben Sie die E-Mail-Adresse angegeben, mit der Sie sich für einen Termin angemeldet haben?</p>
          <button class="btn white mr3 mb2" @click="tryAgain()">Erneut versuchen</button>
          <button class="btn ghostbtn white mb3" @click="changeStep(1001)">Zuück zur Terminvergabe</button>
        </div>

        <div class="col-12 lg-col-7 lg-pl3 pl2 lg-pr5 lg-pt1 mx-auto border-box" v-if="dataFound == true">
          <h2 class="h2 mb2">Ihr Stornierungslink:</h2>
          <div>
            <a :href="link">{{link}}</a>
          </div>
          <button class="btn white mt4 mb3" @click="changeStep(1001)">Zurück zur Terminvergabe</button>
        </div>

            <div class="col-12 lg-col-7 lg-pl3 pl2 lg-pr5 lg-pt1 mx-auto border-box" v-if="!anfrageRunning && !dataNotFound && !dataFound">
              <h2 class="h3-5 gray condensed pb2 pt2">Bitte füllen Sie das Formular aus, um Ihren Termin für die Stornierung herauszusuchen:</h2>
              <div v-if="showTestCenterSelection">
                  <p class="color1 p-medium light mb0-5" v-bind:class="{ error: not_filled_customer_email}">Bitte Testzentrum wählen</p>
                  <select class="forminput" :class="{error: not_filled_customer_email}" v-model="testcenter">
                      <option value="1">{{texts.TESTCENTER_NAME_1}}</option>
                      <option value="2">{{texts.TESTCENTER_NAME_2}}</option>
                  </select>
                  <div class="mb1"></div>
              </div>
              <p class="color1 p-medium light mb0-5" v-bind:class="{ error: not_filled_customer_email}">E-Mail-Adresse: *</p>
              <input class="forminput" :class="{error: not_filled_customer_email}" v-model="customer_email" placeholder="" type="email">
              <div class="mb1"></div>

              <p class="color1 p-medium light mb0-5" v-bind:class="{ error: not_filled_customer_testday}">Testtag *</p>
              <input type="date" v-model="customer_testday" class="forminput" :class="{error: not_filled_customer_testday}" />
              <div class="mb1"></div>

              <p class="color1 p-medium light mb0-5" v-bind:class="{ error: not_filled_customer_bookingcode}">Buchungsnummer / Testnummer *</p>
              <input class="forminput" :class="{error: not_filled_customer_bookingcode}" v-model="customer_bookingcode" placeholder="">
              <div class="mb1"></div>

              <div class="mb3"></div>
                  <submit-button :name="buttonName" :validInput="isValid" event_name="pre_submit_step_app2000" :btnAnimation="btnAnimation"/>
              <div class="mb3"></div>
              
              <div class="mb4"></div>
            </div>
            <cta-support-element :appointment="appointment"/>
    </div>
</template>

<script>

import axios from 'axios'
import { EventBus } from "@/event-bus.js";
import SubmitButton from './SubmitButton.vue'
import CTASupportElement from './CTASupportElement.vue'


export default {
  name: 'CancelRequest',
  props: {
    name: String,
    event_name: String,
    button_style: String,
    appointment: Boolean,
    onBoardingAppointmentDateDefault: String,
    onBoardingAppointmentTimeDefault: String,
  },
  data() {
    return {
      dataNotFound: false,
      dataFound: false,
      link: '',
      debug: false,
      details: false,
      style_class: '',
      customer_email: '',
      customer_testday: '',
      customer_bookingcode: '',
      not_filled_customer_email: false,
      not_filled_customer_testday: false,
      not_filled_customer_bookingcode: false,
      testcenter: 1,
      cancelRequestData: {
        customer_cancelRequest_email: "",
        customer_cancelRequest_testday: "",
        customer_cancelRequest_bookingcode: "",
      },
      buttonName: 'Termin jetzt suchen um ihn zu stornieren',
      btnAnimation: false,
      isValid: false,
      nextDate: '',
      anfrageRunning: false,
      texts: {
        INSTANCE_NAME: process.env.VUE_APP_INSTANCE_NAME,
        PRIVACY_LINK: process.env.VUE_APP_FOOTER_LINKS_PRIVACY,
        TESTCENTER_NAME_1: process.env.VUE_APP_TESTZENTRUM,
        TESTCENTER_NAME_2: process.env.VUE_APP_SECOND_TESTZENTRUM,
        
      },
      SECONDTESTCENTER: process.env.VUE_APP_SECONDTESTCENTERACTIVE,
      showTestCenterSelection: false
    }
  },
  created() {
    if(this.style != "") {
      this.style_class = this.style;
    }
    
    if(this.SECONDTESTCENTER == 'true') {
        this.showTestCenterSelection = true;
    }

    EventBus.$on('pre_submit_step_app2000', () => { // Kein payload!
      this.log('OnboardingStepApp2000.vue: Event received: pre_submit_step_app2000');

      var allElementsReady = true;

      if(/(.+)@(.+){2,}\.(.+){2,}/.test(this.customer_email)){
        this.not_filled_customer_email = false;
      }else{
        this.not_filled_customer_email = true;
        allElementsReady= false;
      }

      this.log('OnboardingStepApp2000.vue: allElementsReady: ' +  allElementsReady);
      if(allElementsReady) {
        this.btnAnimation = true
        let this1 = this
        setTimeout(function(){ 
          // console.log('test')
          // this1.buttonName = 'Jetzt Zeitwert erfahren!'
          EventBus.$emit('submit_step_app2000', {
            'app_cancelRequest_email': this1.customer_email,
            'app_cancelRequest_birthday': this1.customer_testday,
            'app_cancelRequest_bookingcode': this1.customer_bookingcode,
          });
          this1.btnAnimation = false;
          this1.anfrageRunning = true;
        }, 500);
      }
    });
    EventBus.$on('submit_step_app2000', (payload) => {
        
        this.cancelRequestData.customer_cancelRequest_testday = payload.app_cancelRequest_birthday;
        this.cancelRequestData.customer_cancelRequest_email = payload.app_cancelRequest_email;
        this.cancelRequestData.customer_cancelRequest_bookingcode = payload.app_cancelRequest_bookingcode;
        
        this.log('App.vue: Event received: submit_step_app2000');
        this.makeAppointmentCancelRequest();
        
    });
    EventBus.$on('resetForm', () => { // Keine payload! 
        this.dataNotFound = false;
        this.dataFound = false;
        this.anfrageRunning = false;
        this.custumer_email = '';
        this.customer_testday = '';
        this.customer_bookingcode = '';


    });
  }, 
  watch: {
    customer_email: function (newValue, oldValue){
      if(newValue != oldValue) {
        this.checkValidation()
      }      
    },
    customer_testday: function(newValue, oldValue) {
      if(newValue != oldValue) {
        this.checkValidation()
      }
    },
    customer_bookingcode: function (newValue, oldValue){
      if(newValue != oldValue) {
        this.checkValidation()
      }      
    },
  },
  components: {
    'submit-button' : SubmitButton,
    'cta-support-element': CTASupportElement,
  },
  methods: {
    submit: function() {
        this.log('OnboardingStepApp03.vue: Emitting Event using Submit: ' + this.event_name);
        EventBus.$emit(""+this.event_name);
    },
    log: function (obj) {
      if(this.debug) {
        console.log(obj)
      }
    },
    changeStep(step){
      EventBus.$emit('changeStep', {
            'step_number': step
          });
    },
    checkValidation(){
        if(
        this.customer_email != "" &&
        this.customer_testday != "" &&
        this.customer_bookingcode != "") {
          this.isValid = true;
      }else{
        this.isValid = false;
      }
    },

    makeAppointmentCancelRequest(){

        let createURL = process.env.VUE_APP_APIENDPOINT + '/onboarding/appointments/cancellation/findappointment/json';
        if(this.testcenter == 2) {
            createURL = process.env.VUE_APP_SECOND_APIENDPOINT + '/onboarding/appointments/cancellation/findappointment/json';
        }
        let currentObj = this;

        var d = this.cancelRequestData.customer_cancelRequest_testday.split("-")
        var fancyDate = d[2] + "." + d[1] + "." + d[0];
        console.log('fancydate: ' + fancyDate)

        axios.post(createURL, {
          'date': fancyDate,
          'bookingcode': this.cancelRequestData.customer_cancelRequest_bookingcode,
          'customer_email': this.cancelRequestData.customer_cancelRequest_email
       
        }).then(function (response) {
          currentObj.apiResponse = response.data;
          console.log( response.data);
          if(response.data.appointmentFound == true) {
            console.log('Buchungsdaten gefunden!')
            currentObj.link = response.data.link;
            window.location.href = response.data.link;
            currentObj.anfrageRunning = false
            currentObj.dataFound = true
          } 
          else{
            console.log('Date not found')
            currentObj.anfrageRunning = false
            currentObj.dataNotFound = true
          }
        })
        .catch(function (error) {
          currentObj.log('catch Function of AJAX-Called... error()');
          currentObj.log(error);
          currentObj.apiResponse = error;
        });
     },
    tryAgain(){
      this.dataNotFound = false;
      this.dataFound = false;
      this.anfrageRunning = false;
      this.custumer_email = '';
      this.customer_testday = '';
      this.customer_bookingcode = '';
     }
  }
  
}
</script>

<style scoped>
.h2_backwards {
  color: #9395a6;
  font-size: 20px;
}
.privacy_text {
    font-size: 13px;
}
.greenborder{
  outline: 2px solid #689b72;
}
/* The container */
.dsgvo-container {
  display: block;
  position: relative;
  padding-left: 48px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  line-height: 17px;
  font-weight: 300;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.dsgvo-container input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

/* Create a custom checkbox */
.dsgvo-check {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 24px;
  width: 24px;
  border: 3px solid #06c;
  box-sizing: border-box;
}

/* On mouse-over, add a grey background color */
/* .checkbox-container:hover input[type=radio] ~ .checkmark {
  background-color: #ccc;
} */

/* When the checkbox is checked, add a blue background */
/* .checkbox-container input[type=radio]:checked ~ .checkmark {
  background-color: #2196F3;
} */

/* Create the checkmark/indicator (hidden when not checked) */
.dsgvo-check::before {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.dsgvo-container input[type=checkbox]:checked ~ .dsgvo-check::before {
  display: block;
}

.dsgvo-tick{
  position: absolute; 
  top: 2px;
  left: 2px;
}
.dsgvo-tick svg{
  width:14px;
}

.ghostbtn{
  color: darkred;
  border: 2px solid darkred;
}
.ghostbtn:hover{
  color: white;
}

</style>