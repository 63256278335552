<template>

        <div class="bar flex relative max-width-6 col-12 lg-col-12 mb2 pb2" id="Navbar">
          <div class="logo">
            <a :href="texts.LOGO_LINK_LEFT">
              <img :src="texts.LOGO_URL_LEFT" style="width: 100%; object-fit: contain;">
            </a>        
          </div>
           <div class="logo logo2">
             <a class="mr3" :href="texts.LOGO_LINK_RIGHT">
              <img :src="texts.LOGO_URL_RIGHT" style="width: 100%; object-fit: contain;">
            </a>         
          </div>
      </div>
     
</template>

<script>


export default {
  name: "Burger",
  data(){
    return {
      hideMenu: true,
      texts: {
        LOGO_URL_LEFT: process.env.VUE_APP_LOGO_URL_LEFT,
        LOGO_URL_RIGHT: process.env.VUE_APP_LOGO_URL_RIGHT,
        LOGO_LINK_LEFT: process.env.VUE_APP_LOGO_LINK_LEFT,
        LOGO_LINK_RIGHT: process.env.VUE_APP_LOGO_LINK_RIGHT,
     
      }
    }
  },
  methods: {
    hidemenu(){
      this.hideMenu = true;
    },
    showmenu(){
      this.hideMenu = false;
    }
  }
};

</script>
<style>
#navbarcloseholder {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: inline;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
nav.mainnav * {
    font-size: 1.2rem;
    color: black;
    line-height: 2.5;
    font-weight: 300;
}
.burger a:visited{color: black;}
.burger a:hover{color: #06c;}
ul {
    list-style: none;
    padding-left: 20px;
    font-weight: 300;
    margin: 1.333rem 0;
}
a.absolute.close {
    right: .5rem;
}
.menuholder{
  min-width: 200px;
}
.bg-shadow {
    box-shadow: 0 4px 16px -8px rgba(0,0,0,.7);
}
.burgericon:after, .close:after{
    position: absolute;
    top: 24px;
    text-align: center;
    color: #06c;
    font-weight: 700;
    font-size: .8rem;
    padding-top: 24px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.close:after {
  content: "Schließen";
}
.burgericon:after{
  content:'Menü';
  left: 16px;
}
.close:after {
    text-align: right;
    right: 0;
}
.logo a svg {max-width:100%}
.logo{
  display:flex;
  left: auto;
  transform:none;
  top: auto;
  width:30%;
  z-index: 2;
}
.logo a svg {
  max-width:100%
}
@media (max-width:1280px) {
  .logo{
    width:40%;padding-left:2rem;padding-top:0.25rem
  }
}
a.absolute.close{    
  right: 0.5rem;
}
.close:after {
  text-align: right;
  right: 0;
}
</style>
