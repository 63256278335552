<template>
    <div class="flex justify-center form-box row-reverse col-12 mx-auto">
        <div class="col-12 lg-col-7 lg-pl3 pl2 lg-pr5 lg-pt1 mx-auto border-box" v-if="anfrageRunning">
          Bitte haben Sie einen Moment Geduld, Ihre Anfrage wird jetzt verarbeitet....
          <div style="text-align: center; margin-top: 2rem">
              <img src="/assets/ajax-loader.gif">
            </div>
        </div>
            <div class="col-12 lg-col-7 lg-pl3 pl2 lg-pr5 lg-pt1 mx-auto border-box" v-if="!anfrageRunning">
              <p v-if='this.paymentEnabled == "true"' class="p-medium gray light mt2 ls-1">4 von 4 Angaben</p>
              <p v-if='this.paymentEnabled != "true"' class="p-medium gray light mt2 ls-1">3 von 3 Angaben</p>
              <div class="flex stage-container mb3 small-border-radius">
                <div class="formstage bgcolor1"></div>
                <div class="formstage bgcolor1"></div>
                <div v-if='this.paymentEnabled == "true"' class="formstage bgcolor1"></div>
                <div class="formstage bgcolor1"></div>
              </div>
              <h2 class="h3-5 gray condensed pb2 pt2">Für die Durchführung benötigen wir Ihre persönlichen Angaben:</h2>
              <h2 class="h2 h2_backwards condensed pb2 lh-2">Sie haben dieses Datum gewählt:
                <br> 
                <span class="color1 pointer" @click="changeStep(step = 1001)">{{onBoardingAppointmentDateDefault}}</span>
              </h2>
              <h2 class="h2 h2_backwards condensed pb2 lh-2">Sie haben diese Uhrzeit gewählt:
                <br> 
                <span class="color1 pointer" @click="changeStep(step = 1002)">{{onBoardingAppointmentTimeDefault}}</span><span> Uhr</span>
              </h2>
              <h2 v-if="onBoardingAppointmentProductDefault != 'none'" class="h2 h2_backwards condensed pb2 lh-2">Produkt-Auswahl:
                <br> 
                <span class="color1 pointer" @click="changeStep(step = 1003)">{{onBoardingAppointmentProductDefault}}</span>
              </h2>
              <h2 v-if="onBoardingAppointmentPaymentDefault != 'none'"  class="h2 h2_backwards condensed pb2 lh-2">Zahlungsweise:
                <br> 
                <span class="color1 pointer" @click="changeStep(step = 1003)">{{onBoardingAppointmentPaymentDefault}}</span>
              </h2>
              <h2 class="h2 h2_backwards condensed pb3 lh-2" v-if="SECONDTESTCENTER == 'true'">Gewähltes Testzentrum:
                <br> 
                <span class="color1 pointer" @click="changeStep(step = 999)">{{texts.TESTZENTRUM}}</span>
              </h2>

              <p class="color1 p-medium light mb0-5" v-bind:class="{ error: not_filled_customers_name}">Vorname + Nachname: *</p>
              <input class="forminput" v-bind:class="{ error: not_filled_customers_name}" placeholder="" v-model="customers_name">
              <div class="mb1"></div>

              <p class="color1 p-medium light mb0-5" v-bind:class="{ error: not_filled_customer_email}">E-Mail-Adresse: *</p>
              <input class="forminput" :class="{error: not_filled_customer_email}" v-model="customer_email" placeholder="" type="email">
              <div class="mb1"></div>

              <p class="color1 p-medium light mb0-5" v-bind:class="{ error: not_filled_customer_birthday}">{{texts.BIRTHDAY_TEXT}}
                
                <span v-if="texts.BIRTHDAY_TEXT_HINT !='' && texts.BIRTHDAY_TEXT_HINT != undefined "> (<b>{{texts.BIRTHDAY_TEXT_HINT}}</b>)</span>:
                <span v-if="texts.BIRTHDAY_REQUIRED_OPTIONAL_OR_HIDDEN=='required'">*</span></p>
              <birth-datepicker v-model="customer_birthday" :locale="month_array" class="forminput" :class="{error: not_filled_customer_birthday}" />


              <div v-if="identOption == true">
                   <p class="color1 p-medium light mb0-5" v-bind:class="{ error: not_filled_customer_postalcode}">(Personal-) Ausweisnummer (<b>optional</b>):</p>
                  <input class="forminput" :class="{error: not_filled_customer_ident}" v-model="customer_ident" placeholder="">
                  <div class="mb1"></div>
              </div>

              <!--<v-date-picker class="mb3" is-expanded v-model="customer_birthday" color="red" locale="de-de"/>-->
              <div class="mb1"></div>
                <div v-if="texts.PHONE_REQUIRED_OPTIONAL_OR_HIDDEN!='hidden'">
                <p class="color1 p-medium light mb0-5" v-bind:class="{ error: not_filled_customer_contact_phonenumber}">{{texts.PHONE_TEXT}}
                  
                  <span v-if="texts.PHONE_TEXT_HINT!=''"> (<b>{{texts.PHONE_TEXT_HINT}}</b>)</span>:
                  <span v-if="texts.PHONE_REQUIRED_OPTIONAL_OR_HIDDEN=='required'">*</span></p>

                <vue-tel-input mode="international" class="forminput" v-bind:class="{ error: not_filled_customer_contact_phonenumber}" placeholder="" v-model="customer_contact_phonenumber"></vue-tel-input>
                <div class="mb1"></div>
              </div>
              <div v-if="texts.STREET_REQUIRED_OPTIONAL_OR_HIDDEN!='hidden'">
                <p class="color1 p-medium light mb0-5" v-bind:class="{ error: not_filled_customer_address}">{{texts.STREET_TEXT}}
                  <span v-if="texts.STREET_TEXT_HINT!=''"> (<b>{{texts.STREET_TEXT_HINT}}</b>)</span>:
                  <span v-if="texts.STREET_REQUIRED_OPTIONAL_OR_HIDDEN=='required'">*</span>
                </p>
                <input class="forminput" :class="{error: not_filled_customer_address}" v-model="customer_address" placeholder="">


                <div class="mb1"></div>
              </div>
             
              <div v-if="texts.CITY_REQUIRED_OPTIONAL_OR_HIDDEN!='hidden'">
                   <p class="color1 p-medium light mb0-5" v-bind:class="{ error: not_filled_customer_postalcode}">{{texts.CITY_TEXT}}
                    <span v-if="texts.CITY_TEXT_HINT!=''"> (<b>{{texts.CITY_TEXT_HINT}}</b>)</span>:
                    <span v-if="texts.CITY_REQUIRED_OPTIONAL_OR_HIDDEN=='required'">*</span>
                  </p>
                  <input class="forminput" :class="{error: not_filled_customer_postalcode}" v-model="customer_postalcode" placeholder="">
                  <div class="mb1"></div>
              </div>

               <label class="dsgvo-container" style="margin-bottom: 2rem;" v-if="coronaWarnAppOption">
                  <input type="checkbox" v-model="customer_coronawarnapp" @click="customer_coronawarnapp = !customer_coronawarnapp" v-bind:class="{ error: not_filled_customer_coronawarnapp}">
                  <span class="dsgvo-check">
                      <span class="dsgvo-tick" v-if="customer_coronawarnapp">
                        <svg width="15px" height="12px" viewBox="0 0 15 12" version="1.1">
                          <g id="Screens" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Styles_UI" transform="translate(-430.000000, -1562.000000)" fill="red">
                              <polygon id="Path" points="432.452132 1565.81544 435.616503 1569.02535 442.547868 1562 445 1564.48736 438.074306 1571.51264 435.616503 1574 430 1568.3028"></polygon>
                            </g>
                          </g>
                        </svg>

                    </span>
                  </span>
                  <b>Optional:</b> Ich möchte, dass mein Testergebnis an die Corona-Warn-App übertragen werden kann.
              </label>
              <div v-if="coronaWarnAppOption">
                <label class="dsgvo-container cwa-style" v-if="customer_coronawarnapp">
                    <input type="radio" v-model="customer_coronawarnapp_type" value="anonym" name="customer_coronawarnapp_type"> 
                    <p> <b>Einwilligung zur pseudonymisierten Übermittlung (Nicht-Namentliche Anzeige) </b> <br>
                      Hiermit erkläre ich mein Einverständnis zum Übermitteln meines Testergebnisses und meines pseudonymen Codes an das Serversystem der RKI, damit ich mein Testergebnis mit der Corona-Warn-App abrufen kann. Das Testergebnis in der App kann hierbei nicht als namentlicher Testnachweis verwendet werden. Ich habe die <a href="/assets/Datenschutz-Hinweis-Schnittstelle.pdf" target="_blank">Datenschutzhinweise</a> gelesen. </p>
                </label>
                <label class="dsgvo-container cwa-style" v-if="customer_coronawarnapp">
                    <input type="radio" v-model="customer_coronawarnapp_type" value="personal" name="customer_coronawarnapp_type"> 
                    <p><b> Einwilligung zur personalisierten Übermittlung (Namentlicher Testnachweis)</b> <br>
                    Hiermit erkläre ich mein Einverständnis zum Übermitteln des Testergebnisses und meines pseudonymen Codes an das Serversystem des RKI, damit ich mein Testergebnis mit der Corona-Warn-App abrufen kann. Ich willige außerdem die Übermittlung meines Namens und Geburtsdatums an die App ein, damit mein Testergebnis in der App al namenlticher Testnachweis angezeigt werden kann. Ich habe die <a href="/assets/Datenschutz-Hinweis-Schnittstelle.pdf" target="_blank">Datenschutzhinweise</a> gelesen. </p>
                </label>
                <span class="cwa-type-error" v-if="customer_coronawarnapp_type_error">Bitte treffen Sie ein Auswahl.</span>
              </div>
              <label class="dsgvo-container">
                  <input type="checkbox" v-model="customers_dsgvo" @click="customers_dsgvo = !customers_dsgvo" v-bind:class="{ error: not_filled_customers_dsgvo}">
                  <span class="dsgvo-check">
                      <span class="dsgvo-tick" v-if="customers_dsgvo">
                        <svg width="15px" height="12px" viewBox="0 0 15 12" version="1.1">
                          <g id="Screens" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Styles_UI" transform="translate(-430.000000, -1562.000000)" fill="red">
                              <polygon id="Path" points="432.452132 1565.81544 435.616503 1569.02535 442.547868 1562 445 1564.48736 438.074306 1571.51264 435.616503 1574 430 1568.3028"></polygon>
                            </g>
                          </g>
                        </svg>

                    </span>
                  </span>
                  Ich bin damit einverstanden, dass {{texts.DER_SNIPPET}} {{texts.INSTANCE_NAME}} meine Kontaktdaten für die Kontaktaufnahme speichert. Weitere Informationen entnehme ich der <a :href="texts.PRIVACY_LINK" target="_blank">Datenschutzerklärung</a>. *
              </label>

              <div class="mb3"></div>
                  <submit-button :name="buttonName" :validInput="isValid" event_name="pre_submit_step_app400" :btnAnimation="btnAnimation"/>
              <div class="mb3"></div>
              
              <div class="mb4"></div>
            </div>
            <cta-support-element :appointment="appointment"/>
    </div>
</template>

<script>

import { EventBus } from "@/event-bus.js";
import SubmitButton from './SubmitButton.vue'
import CTASupportElement from './CTASupportElement.vue'
import birthDatepicker from 'vue-birth-datepicker';


export default {
  name: 'AppointmentStep04',
  props: {
    name: String,
    event_name: String,
    button_style: String,
    appointment: Boolean,
    onBoardingAppointmentDateDefault: String,
    onBoardingAppointmentTimeDefault: String,
    onBoardingAppointmentProductDefault: String,
    onBoardingAppointmentPaymentDefault: String,    
    onBoardingAppointmentTestcenter: Number
  },
  data() {
    return {
      debug: false,
      details: false,
      style_class: '',
      customers_name: '',
      customer_email: '',
      customer_contact_phonenumber: '',
      customers_dsgvo: '',
      customer_address: '',
      customer_postalcode: '',
      customer_birthday: '',
      customer_ident: '',
      customer_coronawarnapp: '',
      customer_coronawarnapp_type: '',
      not_filled_customers_name: false,
      not_filled_customer_email: false,
      not_filled_customer_contact_phonenumber: false,
      not_filled_customer_birthday: false,
      not_filled_customer_address: false,
      not_filled_customer_postalcode: false,
      not_filled_customers_dsgvo: false,
      buttonName: 'Termin jetzt buchen!',
      btnAnimation: false,
      isValid: false,
      nextDate: '',
      anfrageRunning: false,

      coronaWarnAppOption: false,
      customer_coronawarnapp_type_error: false,
      identOption: false,

      texts: {
        INSTANCE_NAME: process.env.VUE_APP_INSTANCE_NAME,
        PRIVACY_LINK: process.env.VUE_APP_FOOTER_LINKS_PRIVACY,
        TESTZENTRUM: process.env.VUE_APP_TESTZENTRUM,

        PHONE_REQUIRED_OPTIONAL_OR_HIDDEN: process.env.VUE_APP_PHONE_REQUIRED_OPTIONAL_OR_HIDDEN,
        PHONE_TEXT: process.env.VUE_APP_PHONE_TEXT,
        PHONE_TEXT_HINT: process.env.VUE_APP_PHONE_TEXT_HINT,

        BIRTHDAY_REQUIRED_OPTIONAL_OR_HIDDEN: process.env.VUE_APP_BIRTHDAY_REQUIRED_OPTIONAL_OR_HIDDEN,
        BIRTHDAY_TEXT: process.env.VUE_APP_BIRTHDAY_TEXT,
        BIRTHDAY_TEXT_HINT: process.env.VUE_APP_BIRTHDAY_TEXT_HINT,

        STREET_REQUIRED_OPTIONAL_OR_HIDDEN: process.env.VUE_APP_STREET_REQUIRED_OPTIONAL_OR_HIDDEN,
        STREET_TEXT: process.env.VUE_APP_STREET_TEXT,
        STREET_TEXT_HINT: process.env.VUE_APP_STREET_TEXT_HINT,

        CITY_REQUIRED_OPTIONAL_OR_HIDDEN: process.env.VUE_APP_CITY_REQUIRED_OPTIONAL_OR_HIDDEN,
        CITY_TEXT: process.env.VUE_APP_CITY_TEXT,
        CITY_TEXT_HINT: process.env.VUE_APP_CITY_TEXT_HINT,


        DER_SNIPPET: 'der'

       
      },
      
      month_array: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez'
      ],
      SECONDTESTCENTER: process.env.VUE_APP_SECONDTESTCENTERACTIVE,
      paymentEnabled: process.env.VUE_APP_SHOW_PAYMENT_OPTION,
    }
  },
  created() {

    if( process.env.VUE_APP_SHOW_CORONA_WARNAPP_OPTION == "true")
    {
      this.coronaWarnAppOption=true; 
    }
    if( process.env.VUE_APP_SHOW_IDENTNUMBER_OPTION == "true")
    {
      this.identOption=true; 
    }
    if(this.style != "") {
      this.style_class = this.style;
    }
    console.log('B this.texts 1');
    console.log(this.texts);
    console.log('E this.texts 1');

    if(process.env.VUE_APP_DSGVO_TEXT_SNIPPET != undefined) {
      if(process.env.VUE_APP_DSGVO_TEXT_SNIPPET.length > 0) {
        this.texts.DER_SNIPPET = process.env.VUE_APP_DSGVO_TEXT_SNIPPET;
      }
    }

    // Handler for missing .env's
    if(this.texts.BIRTHDAY_REQUIRED_OPTIONAL_OR_HIDDEN == undefined)
    {
      this.texts.BIRTHDAY_REQUIRED_OPTIONAL_OR_HIDDEN = 'required';
    }

    if(this.texts.PHONE_REQUIRED_OPTIONAL_OR_HIDDEN == undefined)
    {
      this.texts.PHONE_REQUIRED_OPTIONAL_OR_HIDDEN = 'required';
    }

    if(this.texts.STREET_REQUIRED_OPTIONAL_OR_HIDDEN == undefined)
    {
      this.texts.STREET_REQUIRED_OPTIONAL_OR_HIDDEN = 'required';
    }

    if(this.texts.CITY_REQUIRED_OPTIONAL_OR_HIDDEN == undefined)
    {
      this.texts.CITY_REQUIRED_OPTIONAL_OR_HIDDEN = 'required';
    }

    
    if(this.texts.PHONE_TEXT == undefined)
    {
      this.texts.PHONE_TEXT = 'Mobilfunknummer';
    }

    if(this.texts.BIRTHDAY_TEXT == undefined)
    {
      this.texts.BIRTHDAY_TEXT = 'Geburtstag';
    }

    if(this.texts.PHONE_TEXT_HINT == undefined)
    {
      this.texts.PHONE_TEXT_HINT = 'für SMS Empfang!';
    }

    if(this.texts.STREET_TEXT == undefined)
    {
      this.texts.STREET_TEXT = 'Straße & Hausnummer';
    }

    if(this.texts.CITY_TEXT == undefined)
    {
      this.texts.CITY_TEXT = 'Wohnort & PLZ';
    }

     if(this.texts.STREET_TEXT_HINT == undefined)
    {
      this.texts.STREET_TEXT_HINT = '';
    }

     if(this.texts.CITY_TEXT_HINT == undefined)
    {
      this.texts.CITY_TEXT_HINT = '';
    }

    console.log('B this.texts 2');
    console.log(this.texts);
    console.log('E this.texts 2ou');


    EventBus.$on('pre_submit_step_app400', () => { // Kein payload!
      this.log('OnboardingStepApp300.vue: Event received: pre_submit_step_app400');

      var allElementsReady = true;
      
      if(this.customers_name == "") {
        this.customers_name = 'Keine Angabe';
      }

      if(/(.+)@(.+){2,}\.(.+){2,}/.test(this.customer_email)){
        this.not_filled_customer_email = false;
      }else{
        this.not_filled_customer_email = true;
        allElementsReady= false;
      }

    
      if(this.customers_dsgvo != "") {
        this.not_filled_customers_dsgvo = false;
      } else {
        this.not_filled_customers_dsgvo = true;
        allElementsReady= false;
      }

      if(this.customer_coronawarnapp) {
        if(this.customer_coronawarnapp_type == '') {
          allElementsReady= false;
          this.customer_coronawarnapp_type_error = true;
        } else {
          this.customer_coronawarnapp_type_error = false;
        }
      } else {
        this.customer_coronawarnapp_type_error = false;
      }

      this.log('OnboardingStepApp300.vue: allElementsReady: ' +  allElementsReady);
      if(allElementsReady) {
        this.btnAnimation = true
        let this1 = this
        setTimeout(function(){ 
          // console.log('test')
          // this1.buttonName = 'Jetzt Zeitwert erfahren!'
          EventBus.$emit('submit_step_app400', {
            'app_customers_name': this1.customers_name,
            'app_customers_email': this1.customer_email,
            'app_customers_birthday': this1.customer_birthday,
            'app_customer_contact_phonenumber': this1.customer_contact_phonenumber,
            'app_customer_contact_address': this1.customer_address,
            'app_customer_contact_postalcode': this1.customer_postalcode,
            'app_customers_dsgvo': this1.customers_dsgvo,
            'app_customer_coronawarnapp': this1.customer_coronawarnapp,
            'app_customer_coronawarnapp_type': this1.customer_coronawarnapp_type,
          });
          this1.btnAnimation = false;
          this1.anfrageRunning = true;
        }, 500);
      }
    });
  }, 
  watch: {
    customers_name: function (newValue, oldValue){
      if(newValue != oldValue) {
        this.checkValidation()
      }
    },
    customer_contact_phonenumber: function (newValue, oldValue){
      if(newValue != oldValue) {
        this.checkValidation()
      }
    },
    customers_dsgvo: function (newValue, oldValue){
      if(newValue != oldValue) {
        this.checkValidation()
      }      
    },
    customer_email: function (newValue, oldValue){
      if(newValue != oldValue) {
        this.checkValidation()
      }      
    },
    customer_birthday: function(newValue, oldValue) {
      if(newValue != oldValue) {
        this.checkValidation()
      }
      /*
      if(newValue != oldValue) {
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        var pickedDate = this.customer_birthday
        if(pickedDate >= tomorrow || pickedDate.toDateString() == tomorrow.toDateString()){
        this.customer_birthday = newValue
        this.isValid = true;
        }else{
          this.isValid = false;
        }
      }
      */
    },
    customer_address: function (newValue, oldValue){
      if(newValue != oldValue) {
        this.checkValidation()
      }      
    },
    customer_postalcode: function (newValue, oldValue){
      if(newValue != oldValue) {
        this.checkValidation()
      }      
    },
     onBoardingAppointmentTestcenter: function(newValue, oldValue) {
       console.log('AppStep01.vue: new onBoardingAppointmentTestcenter found, new: ' + newValue + ' old: ' + oldValue);

         if(newValue == 1) {
            this.texts.TESTZENTRUM = process.env.VUE_APP_TESTZENTRUM;
         }
         if(newValue == 2) {
          this.texts.TESTZENTRUM = process.env.VUE_APP_SECOND_TESTZENTRUM;
         }
         if(newValue == 3) {
          this.texts.TESTZENTRUM = process.env.VUE_APP_THIRD_TESTZENTRUM;
         }
         if(newValue == 4) {
          this.texts.TESTZENTRUM = process.env.VUE_APP_FOURTH_TESTZENTRUM;
         }
     }
  },
  components: {
    'submit-button' : SubmitButton,
    'cta-support-element': CTASupportElement,
    'birth-datepicker': birthDatepicker
  },
  methods: {
    submit: function() {
        this.log('OnboardingStepApp03.vue: Emitting Event using Submit: ' + this.event_name);
        EventBus.$emit(""+this.event_name);
    },
    log: function (obj) {
      if(this.debug) {
        console.log(obj)
      }
    },
    changeStep(step){
      EventBus.$emit('changeStep', {
            'step_number': step
          });
    },
    checkValidation(){

      let valid = true;

      if(this.customers_name == "")
      {
        valid = false;
      }
      if(this.texts.PHONE_REQUIRED_OPTIONAL_OR_HIDDEN == 'required') {
        if(this.customer_contact_phonenumber == "")
        {
          valid = false;
        }
      }
      if(this.texts.BIRTHDAY_REQUIRED_OPTIONAL_OR_HIDDEN == 'required') {
        if(this.customer_birthday == "")
        {
          valid = false;
        }
      }
      if(this.texts.STREET_REQUIRED_OPTIONAL_OR_HIDDEN == 'required') {
        if(this.customer_address == "")
        {
          valid = false;
        }
      }
      if(this.texts.CITY_REQUIRED_OPTIONAL_OR_HIDDEN == 'required') {
        if(this.customer_postalcode == "")
        {
          valid = false;
        }
      }
     
     
      if(this.customers_dsgvo == "")
      {
        valid = false;
      }
      if( /(.+)@(.+){2,}\.(.+){2,}/.test(this.customer_email) == false) {
        valid = false;
      }
      /*
      if(
        this.customers_name != "" &&
        this.customer_contact_phonenumber != "" &&
        this.customer_birthday != "" &&
        this.customer_address != "" &&
        this.customer_postalcode != "" &&
        //&& this.customers_dsgvo != "" &&
        this.customers_dsgvo != "" &&
        /(.+)@(.+){2,}\.(.+){2,}/.test(this.customer_email)
      */
      if(valid) {
          this.isValid = true;
      }else{
        this.isValid = false;
      }
    },
  }
  
}
</script>

<style scoped>

label.dsgvo-container.cwa-style {
    background: silver;
    padding: 1rem;
    margin-left: 3rem;
    display: flex;
}



label.dsgvo-container.cwa-style input[type="radio"] {
    margin-right: 1rem;
}
.h2_backwards {
  color: #9395a6;
  font-size: 20px;
}
.privacy_text {
    font-size: 13px;
}
.greenborder{
  outline: 2px solid #689b72;
}
/* The container */
.dsgvo-container {
  display: block;
  position: relative;
  padding-left: 48px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  line-height: 17px;
  font-weight: 300;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.dsgvo-container input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

/* Create a custom checkbox */
.dsgvo-check {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 24px;
  width: 24px;
  border: 3px solid #06c;
  box-sizing: border-box;
}

/* On mouse-over, add a grey background color */
/* .checkbox-container:hover input[type=radio] ~ .checkmark {
  background-color: #ccc;
} */

/* When the checkbox is checked, add a blue background */
/* .checkbox-container input[type=radio]:checked ~ .checkmark {
  background-color: #2196F3;
} */

/* Create the checkmark/indicator (hidden when not checked) */
.dsgvo-check::before {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.dsgvo-container input[type=checkbox]:checked ~ .dsgvo-check::before {
  display: block;
}

.dsgvo-tick{
  position: absolute; 
  top: 2px;
  left: 2px;
}
.dsgvo-tick svg{
  width:14px;
}


</style>