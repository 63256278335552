<template>
    <div class="flex justify-center form-box row-reverse col-12 mx-auto">
            <div class="col-12 lg-col-7 lg-pl3 pl2 lg-pr5 lg-pt1 mx-auto border-box">
              
              
              <h2 class="h1-5 bold color1 condensed pb3 pt2 ml2 pl1 lh-1">Vielen Dank für Ihre Anfrage</h2>
              <p class="p extra-light gray ml2 pl1 mb4">
                  Bedauerlicherweise können wir in diesem Fall keine automatische Terminveinbarung vornehmen. Daher werden wir uns zeitnahe mit Ihnen in Verbindung setzen, um eine individuelle Bewertung und ein weiteres mögliches Vorgehen zu besprechen.
              </p>
            </div>
            <cta-support-element/>
    </div>
</template>

<script>

import { EventBus } from "@/event-bus.js";
import CTASupportElement from './CTASupportElement.vue'

export default {
  name: 'AppointmentStep04_1',
  props: {
    name: String,
    event_name: String,
    button_style: String,
    onBoardingAppointmentTestcenter: Number

  },
  data() {
    return {
      notfilled: false,
      debug: false,
      

    }
  },
  created() {
    if(this.style != "") {
      this.style_class = this.style;
    }
    // EventBus.$on('submit_step_app100', () => { // Kein payload!
    //   this.log(this);
    //     this.log('OnboardingStep01 Event received: submit_step_app100');
    // });

    EventBus.$on('pre_submit_step_app200', () => {
      if(this.date != "") {
        this.notfilled = false;
          EventBus.$emit('submit_step_app200', {
           "appointment_time": this.time
          });
      } else {
        // TODO: Alertbox nothing inserted!
        this.notfilled = true;
      }
    });

  },
  components: {
    'cta-support-element': CTASupportElement
  },
  methods: {
    submit: function() {
        this.log('Appointment01.vue: Emitting Event using Submit: ' + this.event_name);
        EventBus.$emit(""+this.event_name);
    },
    log: function (obj) {
      if(this.debug) {
        this.log(obj)
      }
    },
    startOnBoarding(){
      EventBus.$emit("start_onboarding");
    }
    
    
  }
}
</script>

<style scoped>
@media(max-width: 769px){
  p{
    margin-left: 0;
    padding-left: 0;
  }
  h2{
    margin-left: 0;
    padding-left: 0;
  }
}
</style>