<template>
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <button type="button" class="btn-close" @click="close">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
              fill="currentColor" />
          </svg>
        </button>
      </header>

      <section class="modal-body">
        <slot name="body">
          <h1><b>Für wen ist die Durchführung eines POC-Tests kostenfrei:</b></h1>
          <br>
          § 4a Testungen bei impfunfähigen und abgesonderten Personenz<br>
          Folgende asymptomatische Personen haben Anspruch auf Testung mittels PoC-Antigentests:
          <br><br>
          1. Personen, die zum Zeitpunkt der Testung das zwölfte Lebensjahr noch nicht vollendet haben oder in den letzten 
          drei Monaten vor der Testung das zwölfte Lebensjahr vollendet haben,
          <br><br>
          2. Personen, die aufgrund einer medizinischen Kontraindikation, insbesondere einer Schwangerschaft im ersten 
          Schwangerschaftsdrittel, zum Zeitpunkt der Testung nicht gegen das Coronavirus SARS-CoV-2 geimpft werden können 
          oder in den letzten drei Monaten vor der Testung aufgrund einer medizinischen Kontraindikation nicht gegen das 
          Coronavirus SARS-CoV-2 geimpft werden konnten,
          <br><br>
          3. bis zum 31. Dezember 2021 Personen, die zum Zeitpunkt der Testung das 18. Lebensjahr noch nicht vollendet haben, 
          zum Zeitpunkt der Testung Schwangere und zum Zeitpunkt der Testung Studierende, bei denen eine Schutzimpfung mit 
          anderen als den vom Paul-Ehrlich-Institut im Internet unter der Adresse 
          <a href="http://www.pei.de/impfstoffe/covid-19" target="_blank">http://www.pei.de/impfstoffe/covid-19</a> 
          genannten Impfstoffen erfolgt ist,
          <br><br>
          4. Personen, die zum Zeitpunkt der Testung an klinischen Studien zur Wirksamkeit von Impfstoffen gegen das 
          Coronavirus SARS-CoV-2 teilnehmen oder in den letzten drei Monaten vor der Testung an solchen Studien 
          teilgenommen haben,
          <br><br>
          5. Personen, die sich zum Zeitpunkt der Testung aufgrund einer nachgewiesenen Infektion mit dem Coronavirus 
          SARS-CoV-2 in Absonderung befinden, wenn die Testung zur Beendigung der Absonderung erforderlich ist.
        </slot>
       </section>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Modal',
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>
<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  .modal {
    background: #FFFFFF;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    padding:20px;
    max-width: 60%;
  }


  .modal-header {
    position: relative;
    justify-content: space-between;
    height:25px;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    color: darkred;
    background: transparent;
  }

  @media screen and (max-width: 898px){
    .modal{
      max-width: 100%;
      height: 100%;
    }
    .modal-header{
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
</style>