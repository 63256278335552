<template>

     <div class="mx-auto py3 flex justify-center flex-wrap">
      <div class="menu-footermenu-container">
        <ul id="menu-footermenu" class="menu">
          <a class="p-medium light black mx2" :href="FOOTER_LINKS_PRIVACY" target="_blank" rel="noreferrer noopener">Datenschutz</a>
          <a class="p-medium light black mx2" :href="FOOTER_LINKS_IMPRINT" target="_blank" rel="noreferrer noopener">Impressum</a>
          <a class="p-medium light black mx2" :href="FOOTER_LINKS_SCHNELLTESTHINWEISE" target="_blank">Corona Schnelltest-Allgemeine Hinweise</a>
          <a class="p-medium light black mx2" @click="startCancelAppointment()" href="#">Termin stornieren?</a>        
        </ul>
      </div>	
    </div>
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  
  name: "Infobox",
  data(){
    return {
      FOOTER_LINKS_IMPRINT: process.env.VUE_APP_FOOTER_LINKS_IMPRINT,
      FOOTER_LINKS_PRIVACY: process.env.VUE_APP_FOOTER_LINKS_PRIVACY,
      FOOTER_LINKS_SCHNELLTESTHINWEISE: process.env.VUE_APP_FOOTER_LINKS_SCHNELLTESTHINWEISE,
      
    }
  },
  methods:{
    startCancelAppointment(){
      EventBus.$emit("cancel_request");
    }
  }
};

</script>