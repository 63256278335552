<template>
    <div class="flex justify-center form-box row-reverse col-12 mx-auto">
            <div class="col-12 lg-col-7 lg-pl3 pl2 lg-pr5 lg-pt1 mx-auto border-box">
              <p class="p-medium gray light mt2 ls-1">Testzentrum wählen:</p>
              <div class="flex stage-container mb3 small-border-radius">
                <div class="formstage step-background-color"></div>
                <div class="formstage step-background-color"></div>
                <div v-if='this.paymentEnabled == "true"' class="formstage step-background-color"></div>
                <div class="formstage step-background-color"></div>
              </div>
              <h2 class="h2-5 gray condensed mb3" v-bind:class="{ error: notfilled}">Bitte wählen Sie ein Testzentrum aus:</h2>
              
              <div class="testzentrum-auswahl flex">
                  <div class="btn ghostbtn col-12 mb3" @click="pickCenter('testcenter_01')" :class="{pickedCenter : testcentrum == 'testcenter_01'}">
                    <h3 class="h3">{{texts.TESTZENTRUM_01}}</h3>
                    <p style="white-space: normal">{{texts.TESTZENTRUM_01_ADDRESS}}</p>
                  </div>
                <div class="mx2"></div>  
                  <div class="btn ghostbtn col-12 mb3" @click="pickCenter('testcenter_02')" :class="{pickedCenter : testcentrum == 'testcenter_02'}">
                    <h3 class="h3">{{texts.TESTZENTRUM_02}}</h3>
                    <p style="white-space: normal">{{texts.TESTZENTRUM_02_ADDRESS}}</p>
                  </div>
              </div>

                <div class="testzentrum-auswahl flex" v-if="texts.TESTZENTRUM_03_ACTIVE=='true'">
                  <div @click="pickCenter('testcenter_03')" v-if="texts.TESTZENTRUM_03_ACTIVE=='true'" class="btn ghostbtn col-12 mb3" :class="{pickedCenter : testcentrum == 'testcenter_03'}">
                    <h3 class="h3">{{texts.TESTZENTRUM_03}}</h3>
                    <p style="white-space: normal">{{texts.TESTZENTRUM_03_ADDRESS}}</p>
                  </div>
                <div class="mx2"></div> 

                <!-- dummy-auswahl, die unsichtbar ist -->
                <div class="col-12 btn" style="pointer-events: none; background-color: rgba(0,0,0,0); margin-bottom: -2rem;"></div>

                  <div @click="pickCenter('testcenter_04')" v-if="texts.TESTZENTRUM_04_ACTIVE=='true'" class="btn ghostbtn col-12 mb3" :class="{pickedCenter : testcentrum == 'testcenter_04'}">
                    <h3 class="h3">{{texts.TESTZENTRUM_04}}</h3>
                    <p style="white-space: normal">{{texts.TESTZENTRUM_04_ADDRESS}}</p>
                  </div>
              </div>

              <!-- <div class="appointment-button-holder align-center"> -->
                <div class="mb2">   
                  <submit-button name="Weiter" :validInput="isValid" event_name="pre_submit_step_app000"/>
                <!-- </div> -->
                <div class="col-12">
                  
                  
                </div> 
              </div>
               <div class="mb4"></div>
              <p v-html="texts.ENTRYTEXT"></p>
               <div class="mb4"></div>
             

            </div>
            <cta-support-element :appointment="appointment"/>
    </div>
</template>

<script>

import { EventBus } from "@/event-bus.js";
import SubmitButton from './SubmitButton.vue'
import CTASupportElement from './CTASupportElement.vue'

export default {
  name: 'AppointmentStep00',
  props: {
    name: String,
    event_name: String,
    button_style: String,
    appointment: Boolean
  },
  data() {
    return {
      notfilled: false,
      debug: false,
      dataEvents: {
        machinecategory_selected: false
      },
      style_class: '',
      testcentrum: '',
     

      isValid: false,
      
      texts: {
        TESTZENTRUM: process.env.VUE_APP_TESTZENTRUM,
        ENTRYTEXT: process.env.VUE_APP_ENTRYTEXT,
        TESTZENTRUM_01: process.env.VUE_APP_TESTZENTRUM,
        TESTZENTRUM_02: process.env.VUE_APP_SECOND_TESTZENTRUM,
        TESTZENTRUM_03: process.env.VUE_APP_THIRD_TESTZENTRUM,
        TESTZENTRUM_04: process.env.VUE_APP_FOURTH_TESTZENTRUM,

        TESTZENTRUM_01_ADDRESS: process.env.VUE_APP_TESTZENTRUM_ADDRESS,
        TESTZENTRUM_02_ADDRESS: process.env.VUE_APP_SECOND_TESTZENTRUM_ADDRESS,
        TESTZENTRUM_03_ADDRESS: process.env.VUE_APP_THIRD_TESTZENTRUM_ADDRESS,
        TESTZENTRUM_04_ADDRESS: process.env.VUE_APP_FOURTH_TESTZENTRUM_ADDRESS,
        TESTZENTRUM_03_ACTIVE:  process.env.VUE_APP_THIRDTESTCENTERACTIVE,
        TESTZENTRUM_04_ACTIVE:  process.env.VUE_APP_FOURTHTESTCENTERACTIVE

      },
      paymentEnabled: process.env.VUE_APP_SHOW_PAYMENT_OPTION,
    }
  },
  created() {

  
    EventBus.$on('pre_submit_step_app000', () => {
      console.log(this.testcentrum);
      if(this.testcentrum != "") {
        this.notfilled = false;
          EventBus.$emit('submit_step_app000', {
           "appointment_center": this.testcentrum
          });
      } else {
        // TODO: Alertbox nothing inserted!
        this.notfilled = true;
      }
    });

  },
  components: {
    'submit-button' : SubmitButton,
    'cta-support-element': CTASupportElement
  },
  methods: {
    submit: function() {
        this.log('Appointment01.vue: Emitting Event using Submit: ' + this.event_name);
        EventBus.$emit(""+this.event_name);
    },
    log: function (obj) {
      if(this.debug) {
        this.log(obj)
      }
    },
    cancelAppointment(){
      EventBus.$emit('appointment_cancel');
    },
    pickCenter(pickedCenter){
      this.testcentrum = pickedCenter;
      if(this.testcentrum != ''){
        this.isValid = true;
      }
    }
    
  }
}
</script>

<style>
  .fw-600{
    font-weight: 600;
  }
  .appointment-button-holder{
    display: block;
  }
  .ghostbtn{
    border: 2px solid darkred !important;
  }
  @media(min-width: 1440px){
    .appointment-button-holder{
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }
    .appointment-button-holder div{
      margin-bottom: 0;
    }

  }
  @media(max-width: 1440px){
    .testzentrum-auswahl{
      flex-direction: column;
    }
  }
  .ghostbtn:hover{
    color:white;
    border: 2px solid red !important;
  }
  .pickedCenter{
    color:white;
    background-color: red !important;
    border: 2px solid red !important;

  }


</style>