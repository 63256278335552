<template>
   <div class="bgcolor1 white lg-px3 mr3 pt3 px1 border-box machine-info">
      <h1 class="h1-5  condensed medium pb1 lh-2 machine-heading" v-if="!(texts.HIDE_CTA_HEADLINE=='true')">Ablauf der kostenfreien Covid-19 Bürgertests</h1>
      <h1 class="h1-5  condensed medium pb1 lh-2 machine-heading" v-if="(texts.HIDE_CTA_HEADLINE=='true')">{{texts.CTA_HEADLINE_OVERRIDETEXT}}</h1>
      <p v-if='this.texts.CTA_POINT_01 != undefined && this.texts.CTA_POINT_01 != ""' class="p-medium mb2 extra-light pt2 lh-2 intro-text ls-1">
        <span class="cta-kugel">1</span> {{texts.CTA_POINT_01}}
      </p>
      <p v-if='this.texts.CTA_POINT_02 != undefined && this.texts.CTA_POINT_02 != ""' class="p-medium mb2 extra-light pt2 lh-2 intro-text ls-1">
        <span class="cta-kugel">2</span> {{texts.CTA_POINT_02}}
      </p>
      <p v-if='this.texts.CTA_POINT_03 != undefined && this.texts.CTA_POINT_03 != ""' class="p-medium mb2 extra-light pt2 lh-2 intro-text ls-1">
        <span class="cta-kugel">3</span> {{texts.CTA_POINT_03}}
      </p>
      <p v-if='this.texts.CTA_POINT_04 != undefined && this.texts.CTA_POINT_04 != ""' class="p-medium mb2 extra-light pt2 lh-2 intro-text ls-1">
        <span class="cta-kugel">4</span> {{texts.CTA_POINT_04}}
      </p>
      <p v-if='this.texts.CTA_POINT_05 != undefined && this.texts.CTA_POINT_05 != ""' class="p-medium mb2 extra-light pt2 lh-2 intro-text ls-1">
        <span class="cta-kugel">5</span> {{texts.CTA_POINT_05}}
      </p>
      <p v-if='this.texts.CTA_POINT_06 != undefined && this.texts.CTA_POINT_06 != ""' class="p-medium mb2 extra-light pt2 lh-2 intro-text ls-1">
        <span class="cta-kugel">6</span> {{texts.CTA_POINT_06}}
      </p>      
      <p v-if='this.texts.CTA_POINT_07 != undefined && this.texts.CTA_POINT_07 != ""' class="p-medium mb2 extra-light pt2 lh-2 intro-text ls-1">
        <span class="cta-kugel">7</span> {{texts.CTA_POINT_07}}
      </p>        
      <p v-if='this.texts.CTA_POINT_08 != undefined && this.texts.CTA_POINT_08 != ""' class="p-medium mb2 extra-light pt2 lh-2 intro-text ls-1">
        <span class="cta-kugel">8</span> {{texts.CTA_POINT_08}}
      </p>        
      <p class="p-medium bold mb2 extra-light pt2 lh-2 intro-text ls-1" style="font-weight: bold;" v-if="texts.SHOW_CTA_SINGLETIMEHINT=='true'">  
          Bitte beachten Sie, dass nur ein Test pro Bürger pro Kalenderwoche kostenfrei durchgeführt werden darf. 
        </p>
        <!--<div class="mx-auto pb4"><a v-if="appointment == false" class="p-tiny btn std center white ghostbtn narrowbtn extra-light col-12 bg-color-none responsive-ghostbtn mb3" href="#" @click="makeAppointment()">Termin vereinbaren</a></div>-->
      <div class="machineform-flex">
       
        
      </div>
    </div>
</template>

<script>

import { EventBus } from "@/event-bus.js";

export default {
  props:{
    appointment: Boolean
  },
  data(){
    return {
      hideMenu: true,
      texts: {
        CTA_POINT_01: process.env.VUE_APP_CTA_POINT_01,
        CTA_POINT_02: process.env.VUE_APP_CTA_POINT_02,
        CTA_POINT_03: process.env.VUE_APP_CTA_POINT_03,
        CTA_POINT_04: process.env.VUE_APP_CTA_POINT_04,
        CTA_POINT_05: process.env.VUE_APP_CTA_POINT_05,
        CTA_POINT_06: process.env.VUE_APP_CTA_POINT_06,
        CTA_POINT_07: process.env.VUE_APP_CTA_POINT_07,
        CTA_POINT_08: process.env.VUE_APP_CTA_POINT_08,
        SHOW_CTA_SINGLETIMEHINT: process.env.VUE_APP_SHOW_CTA_SINGLETIMEHINT,
        HIDE_CTA_HEADLINE: process.env.VUE_APP_HIDE_CTA_HEADLINE,
        
        CTA_HEADLINE_OVERRIDETEXT: process.env.VUE_APP_CTA_HEADLINE_OVERRIDETEXT,
      }
    }
  },
  methods:{
    makeAppointment(){
      EventBus.$emit("appointment_request");
    }
  }
}


</script>

<style scoped>
@media(min-width: 769px){
  .phonelink{
    color: white;
  }
}
span.cta-kugel {
    display: inline-block;
    font-size: 19px;
    line-height: 20px;
    width: 30px;
    height: 30px;
    background: white;
    color: darkred;
    border: 1px solid white;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    font-weight: bold;
    /* margin-top: -10px; */
    /* position: relative; */ 
    /* left: 3px; */
    margin-right: 10px;
    margin-bottom: 10px;
}
</style>