<template>
    <div class="flex justify-center form-box row-reverse col-12 mx-auto">
            <div class="col-12 lg-col-7 lg-pl3 pl2 lg-pr5 lg-pt1 mx-auto border-box">
              <p v-if='this.paymentEnabled == "true"' class="p-medium gray light mt2 ls-1">2 von 4 Angaben</p>
              <p v-if='this.paymentEnabled != "true"' class="p-medium gray light mt2 ls-1">2 von 3 Angaben</p>
              <div class="flex stage-container mb3 small-border-radius">
                <div class="formstage bgcolor1"></div>
                <div class="formstage bgcolor1"></div>
                <div v-if='this.paymentEnabled == "true"' class="formstage step-background-color"></div>
                <div class="formstage step-background-color"></div>
              </div>
              <h2 class="h2-5 gray condensed mb2 pt1" v-bind:class="{ error: notfilled}">Wählen Sie eine Uhrzeit für den Termin.</h2>
              <h2 class="h2 h2_backwards regular condensed pb3 lh-2">Sie haben dieses Datum ausgewählt:
                <br> 
                <span class="color1 pointer" @click="changeStep(step = 1001)">{{onBoardingAppointmentDateDefault}}</span>
              </h2>
              <div  v-if="!allBooked" class="col-12 flex">
                <div class="mb3 col-12 border-box">
                    <div v-for="date in avail_dates" :key="date.time" class="col-12 time-picker-holder" v-bind:class="{ forelementsclass: forelementsclass}">
                         <button class="time-pill gray col-12 center mb3 p" :class="{pickedpill : time == date.time,forelementsclass: forelementsclass}" @click="pickTime( date.time)" v-if="date.avail">
                          {{ date.time }}
                        </button>
                         <button class="time-pill gray col-12 center mb3 p disabled" disabled v-if="!date.avail" :class="{forelementsclass: forelementsclass}" >
                          {{ date.time }} <i>(vergeben)</i>
                        </button>
                    </div>
                   
                </div>
              </div>
               <div  v-if="allBooked" class="col-12 flex">
                 <div class="all-booked-message">
                   Leider sind an diesem Tag ({{onBoardingAppointmentDateDefault}}) keine Termine mehr verfügbar.
                   Bitte wählen Sie einen anderen Tag.
                  </div>
                </div>
              <div class="appointment-button-holder align-center">
                <div class="mb2" v-if="!allBooked">   
                  <submit-button name="Weiter" :validInput="isValid" event_name="pre_submit_step_app200"/>
                </div>
                <div class="col-12">
                  <a @click="cancelAppointment()" class="p color1 underlined">Zurück zum Tagesauswahl</a>  
                </div> 
              </div>
              <div class="mb4"></div>
            </div>
            <cta-support-element :appointment="appointment"/>
    </div>
</template>

<script>

import { EventBus } from "@/event-bus.js";
import SubmitButton from './SubmitButton.vue'
import CTASupportElement from './CTASupportElement.vue'
import axios from 'axios'

export default {
  name: 'AppointmentStep02',
  props: {
    name: String,
    event_name: String,
    button_style: String,
    appointment: Boolean,
    onBoardingAppointmentDateDefault: String,
    onBoardingAppointmentTestcenter: Number

  },
  data() {
    return {
      notfilled: false,
      debug: false,
      style_class: '',
      time: '',
      date:'',
      isValid: false,
      
      avail_dates: [],
      allBooked: false,
      error_message: '',

      APIENDPOINT: process.env.VUE_APP_APIENDPOINT,
      paymentEnabled: process.env.VUE_APP_SHOW_PAYMENT_OPTION,
      FOURAPPOINTSPERLINE: process.env.VUE_APP_FOURAPPOINTSPERLINE,
      forelementsclass: false
    }
  },
  created() {
    if(this.style != "") {
      this.style_class = this.style;
    }
    

    if(this.FOURAPPOINTSPERLINE =="true") {
      this.forelementsclass = true;
    } else {
      this.forelementsclass = false;
    }
     

    EventBus.$on('submit_step_app100', (payload) => {
        console.log(payload)
        var chosenDate = payload.appointment_date;
        var dd = chosenDate.getDate(); 
        var mm = chosenDate.getMonth() + 1; 
        var yyyy = chosenDate.getFullYear(); 
        if (dd < 10) { 
            dd = '0' + dd; 
        } 
        if (mm < 10) { 
            mm = '0' + mm; 
        } 
        var fancyDate = dd + '.' + mm + '.' + yyyy;
        this.date = fancyDate;
        
        let createURL = process.env.VUE_APP_APIENDPOINT + '/onboarding/appointments/checkavailability';
        if(this.onBoardingAppointmentTestcenter == 2) {
          createURL = process.env.VUE_APP_SECOND_APIENDPOINT + '/onboarding/appointments/checkavailability';
        }
        if(this.onBoardingAppointmentTestcenter == 3) {
          createURL = process.env.VUE_APP_THIRD_APIENDPOINT + '/onboarding/appointments/checkavailability';
        }
        if(this.onBoardingAppointmentTestcenter == 4) {
          createURL = process.env.VUE_APP_FOURTH_APIENDPOINT + '/onboarding/appointments/checkavailability';
        }
        let currentObj = this;

        axios.post(createURL, {
          'date': this.date
        }).then(function (response) {
          currentObj.apiResponse = response.data;
          console.log( response.data);
          if(currentObj.apiResponse.okay) {
            console.log('found dates...');
            if(!currentObj.apiResponse.allBooked) {
              console.log('found dates... dates available!');
              currentObj.avail_dates = currentObj.apiResponse.dates;
              currentObj.allBooked = false;
            } else {
              currentObj.allBooked = true;
              
              console.log('found dates... NO dates available!');
               currentObj.error_message = currentObj.apiResponse.message;

            }
          } else {
            currentObj.allBooked = true;
            console.log('found dates... no!');
            currentObj.error_message = currentObj.apiResponse.message;
          }
        })
        .catch(function (error) {
          currentObj.log('catch Function of AJAX-Called... error()');
          currentObj.log(error);
          currentObj.apiResponse = error;
        });

        
    });
    EventBus.$on('pre_submit_step_app200', () => {
      var pickedTime = this.time
      if(pickedTime != ''){
        console.log('Appointment02.vue: Emitting Event submit_step_app200');
        console.log(pickedTime)
        EventBus.$emit('submit_step_app200', {
           "appointment_time": pickedTime
          });
      }
    });

  },

  components: {
    'submit-button' : SubmitButton,
    'cta-support-element': CTASupportElement
  },
  methods: {
    submit: function() {
        this.log('Appointment02.vue: Emitting Event using Submit: ' + this.event_name);
        EventBus.$emit(""+this.event_name);
    },
    log: function (obj) {
      if(this.debug) {
        this.log(obj)
      }
    },
    cancelAppointment(){
      EventBus.$emit('appointment_cancel');
    },
    changeStep(step){
      EventBus.$emit('changeStep', {
            'step_number': step
          });
    },
    pickTime(pickedTime){
      console.log(pickedTime)
      this.time = pickedTime;
      if(this.time != ''){
        this.isValid = true;
      }
    }
    
  }
}
</script>

<style scoped>
  
 .h2_backwards {
  color: #9395a6;
  font-size: 20px;
}
button {
    display: inline-block;
    border: none;
    text-decoration: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
}
.time-pill{
  font-weight: 400;
  padding: 6px;
  border: 1px solid gray;
  border-radius: 6px;
  box-sizing: border-box;
  cursor:pointer;
  transition: all .3s ease;
}
.time-pill:hover{
  background-color: darkred;
  color: white;
}
.pickedpill{
  background-color: red;
  color: white;
}
button.time-pill.gray.col-12.center.mb3.p.disabled {
    cursor: not-allowed;
    background-color: silver;
}
.col-12.time-picker-holder {
    width: 48%;
    display: contents;
}
.all-booked-message {
    font-weight: bold;
    border-radius: 10px;
    border: 2px solid #ff7800;
    padding: 1rem;
    background-color: orange;
    color: white;
    margin-bottom: 2rem;
}
button.time-pill.gray.col-12.center.mb3.p.forelementsclass {
    width: 23%;
    font-size:  14px;
    padding: 6px 0;
}

button.time-pill.gray.col-12.center.mb3.p.disabled.forelementsclass {
    position: relative;
    top: 0px; 
    color: #dddddd;
}
button.time-pill.gray.col-12.center.mb3.p.disabled.forelementsclass i {
    position: absolute;
    top: 0px; 
    left: 0px;
    width: 100%;
    font-size: 10px;
    margin-top: 1.5rem;
    color: grey;
}
@media (max-width: 425px){
  .lg-pr5 {
      padding: 0 .5rem;
  }
}
</style>